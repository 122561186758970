define("discourse/plugins/chat/discourse/components/chat/message-creator/members-selector", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse-common/config/environment", "discourse-common/lib/debounce", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/message-creator/lib/chatables-loader", "discourse/plugins/chat/discourse/components/chat/message-creator/list", "discourse/plugins/chat/discourse/components/chat/message-creator/list-handler", "discourse/plugins/chat/discourse/components/chat/message-creator/members", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _dButton, _environment, _debounce, _discourseI18n, _chatablesLoader, _list, _listHandler, _members, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MembersSelector extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatables", [_tracking.tracked], function () {
      return [];
    }))();
    #chatables = (() => (dt7948.i(this, "chatables"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "filter", [_tracking.tracked], function () {
      return "";
    }))();
    #filter = (() => (dt7948.i(this, "filter"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "highlightedMember", [_tracking.tracked]))();
    #highlightedMember = (() => (dt7948.i(this, "highlightedMember"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "highlightedChatable", [_tracking.tracked]))();
    #highlightedChatable = (() => (dt7948.i(this, "highlightedChatable"), void 0))();
    placeholder = (() => _discourseI18n.default.t("chat.direct_message_creator.group_name"))();
    get items() {
      return this.chatables.filter(c1 => !this.highlightedMemberIds.includes(c1.model.id));
    }
    get highlightedMemberIds() {
      return this.args.members.map(u1 => u1.model.id);
    }
    highlightMember(member1) {
      this.highlightedMember = member1;
    }
    static #_6 = (() => dt7948.n(this.prototype, "highlightMember", [_object.action]))();
    highlightChatable(chatable1) {
      this.highlightedChatable = chatable1;
    }
    static #_7 = (() => dt7948.n(this.prototype, "highlightChatable", [_object.action]))();
    selectChatable(chatable1) {
      if (!chatable1.enabled) {
        return;
      }
      const chatableMembers1 = chatable1.type === "group" ? chatable1.model.chat_enabled_user_count : 1;
      if (this.args.membersCount + chatableMembers1 > this.siteSettings.chat_max_direct_message_users) {
        return;
      }
      if (this.highlightedMemberIds.includes(chatable1.model.id)) {
        this.unselectMember(chatable1);
      } else {
        this.args.onChange?.([...this.args.members, chatable1]);
        this.highlightedChatable = this.items[0];
      }
      this.filter = "";
      this.focusFilterAction?.();
      this.highlightedMember = null;
    }
    static #_8 = (() => dt7948.n(this.prototype, "selectChatable", [_object.action]))();
    registerFocusFilterAction(actionFn1) {
      this.focusFilterAction = actionFn1;
    }
    static #_9 = (() => dt7948.n(this.prototype, "registerFocusFilterAction", [_object.action]))();
    onFilter(event1) {
      this.searchHandler = (0, _debounce.default)(this, this.fetch, event1.target.value, _environment.INPUT_DELAY);
    }
    static #_10 = (() => dt7948.n(this.prototype, "onFilter", [_object.action]))();
    async fetch(term1) {
      this.highlightedMember = null;
      const loader1 = new _chatablesLoader.default(this);
      this.chatables = await loader1.search(term1, {
        includeCategoryChannels: false,
        includeDirectMessageChannels: false,
        excludedMembershipsChannelId: this.args.channel?.id
      });
      this.highlightedChatable = this.items[0];
    }
    static #_11 = (() => dt7948.n(this.prototype, "fetch", [_object.action]))();
    unselectMember(removedMember1) {
      this.args.onChange?.(this.args.members.filter(member1 => member1 !== removedMember1));
      this.highlightedMember = null;
      this.highlightedChatable = this.items[0];
      this.focusFilterAction?.();
    }
    static #_12 = (() => dt7948.n(this.prototype, "unselectMember", [_object.action]))();
    static #_13 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <ListHandler
          @items={{this.items}}
          @highlightedItem={{this.highlightedChatable}}
          @onHighlight={{this.highlightChatable}}
          @onSelect={{this.selectChatable}}
        >
          <div class="chat-message-creator__add-members-header-container">
            <div class="chat-message-creator__add-members-header">
              <Members
                @filter={{this.filter}}
                @members={{@members}}
                @highlightedMember={{this.highlightedMember}}
                @onFilter={{this.onFilter}}
                @registerFocusFilterAction={{this.registerFocusFilterAction}}
                @onHighlightMember={{this.highlightMember}}
                @onSelectMember={{this.unselectMember}}
              />
    
              <DButton
                class="btn-flat chat-message-creator__add-members__close-btn"
                @action={{@cancel}}
                @icon="times"
              />
            </div>
          </div>
    
          <List
            @items={{this.items}}
            @highlightedItem={{this.highlightedChatable}}
            @onSelect={{this.selectChatable}}
            @onHighlight={{this.highlightChatable}}
            @maxReached={{@maxReached}}
            @membersCount={{@membersCount}}
          />
    
        </ListHandler>
      
    */
    {
      "id": "EVrG13JS",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@items\",\"@highlightedItem\",\"@onHighlight\",\"@onSelect\"],[[30,0,[\"items\"]],[30,0,[\"highlightedChatable\"]],[30,0,[\"highlightChatable\"]],[30,0,[\"selectChatable\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"chat-message-creator__add-members-header-container\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"chat-message-creator__add-members-header\"],[12],[1,\"\\n          \"],[8,[32,1],null,[[\"@filter\",\"@members\",\"@highlightedMember\",\"@onFilter\",\"@registerFocusFilterAction\",\"@onHighlightMember\",\"@onSelectMember\"],[[30,0,[\"filter\"]],[30,1],[30,0,[\"highlightedMember\"]],[30,0,[\"onFilter\"]],[30,0,[\"registerFocusFilterAction\"]],[30,0,[\"highlightMember\"]],[30,0,[\"unselectMember\"]]]],null],[1,\"\\n\\n          \"],[8,[32,2],[[24,0,\"btn-flat chat-message-creator__add-members__close-btn\"]],[[\"@action\",\"@icon\"],[[30,2],\"times\"]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[8,[32,3],null,[[\"@items\",\"@highlightedItem\",\"@onSelect\",\"@onHighlight\",\"@maxReached\",\"@membersCount\"],[[30,0,[\"items\"]],[30,0,[\"highlightedChatable\"]],[30,0,[\"selectChatable\"]],[30,0,[\"highlightChatable\"]],[30,3],[30,4]]],null],[1,\"\\n\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@members\",\"@cancel\",\"@maxReached\",\"@membersCount\"],false,[]]",
      "moduleName": "/home/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/members-selector.js",
      "scope": () => [_listHandler.default, _members.default, _dButton.default, _list.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = MembersSelector;
});