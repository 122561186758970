define("discourse/plugins/chat/discourse/components/chat/navbar/new-direct-message-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/modal/new-message", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _discourseI18n, _newMessage, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatNavbarNewDirectMessageButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    buttonLabel = (() => _discourseI18n.default.t("chat.channels_list_popup.browse"))();
    get showButtonComponent() {
      return this.router.currentRoute.name === "chat.direct-messages" && this.canCreateDirectMessageChannel;
    }
    get canCreateDirectMessageChannel() {
      return this.chat.userCanDirectMessage;
    }
    openNewMessageModal() {
      this.modal.show(_newMessage.default);
    }
    static #_4 = (() => dt7948.n(this.prototype, "openNewMessageModal", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.showButtonComponent}}
          <DButton
            class="btn no-text btn-flat c-navbar__new-dm-button"
            title={{this.buttonLabel}}
            @action={{this.openNewMessageModal}}
            @icon="plus"
          />
        {{/if}}
      
    */
    {
      "id": "KOlHG7hK",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"showButtonComponent\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"btn no-text btn-flat c-navbar__new-dm-button\"],[16,\"title\",[30,0,[\"buttonLabel\"]]]],[[\"@action\",\"@icon\"],[[30,0,[\"openNewMessageModal\"]],\"plus\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/home/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/new-direct-message-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatNavbarNewDirectMessageButton;
});