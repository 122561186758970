define("discourse/plugins/chat/discourse/components/chat/routes/channel-info", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse-common/helpers/i18n", "discourse/plugins/chat/discourse/components/chat/modal/edit-channel-name", "discourse/plugins/chat/discourse/components/chat/navbar", "discourse/plugins/chat/discourse/components/chat-channel-status", "discourse/plugins/chat/discourse/components/chat/routes/channel-info-nav", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _i18n, _editChannelName, _navbar, _chatChannelStatus, _channelInfoNav, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatRoutesChannelInfo extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chatChannelInfoRouteOriginManager", [_service.service]))();
    #chatChannelInfoRouteOriginManager = (() => (dt7948.i(this, "chatChannelInfoRouteOriginManager"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "chatGuardian", [_service.service]))();
    #chatGuardian = (() => (dt7948.i(this, "chatGuardian"), void 0))();
    get canEditChannel() {
      return this.chatGuardian.canEditChatChannel() && (this.args.channel.isCategoryChannel || this.args.channel.isDirectMessageChannel && this.args.channel.chatable.group);
    }
    editChannelTitle() {
      return this.modal.show(_editChannelName.default, {
        model: this.args.channel
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "editChannelTitle", [_object.action]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="c-routes --channel-info">
          <Navbar as |navbar|>
            {{#if this.chatChannelInfoRouteOriginManager.isBrowse}}
              <navbar.BackButton
                @route="chat.browse"
                @title={{i18n "chat.channel_info.back_to_all_channels"}}
              />
            {{else}}
              <navbar.BackButton
                @route="chat.channel"
                @routeModels={{@channel.routeModels}}
                @title={{i18n "chat.channel_info.back_to_channel"}}
              />
            {{/if}}
            <navbar.ChannelTitle @channel={{@channel}} />
          </Navbar>
    
          <ChatChannelStatus @channel={{@channel}} />
    
          <div class="c-channel-info">
            <ChannelInfoNav @channel={{@channel}} />
            {{outlet}}
          </div>
        </div>
      
    */
    {
      "id": "Rnb2luAQ",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"c-routes --channel-info\"],[12],[1,\"\\n      \"],[8,[32,0],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"chatChannelInfoRouteOriginManager\",\"isBrowse\"]],[[[1,\"          \"],[8,[30,1,[\"BackButton\"]],null,[[\"@route\",\"@title\"],[\"chat.browse\",[28,[32,1],[\"chat.channel_info.back_to_all_channels\"],null]]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[30,1,[\"BackButton\"]],null,[[\"@route\",\"@routeModels\",\"@title\"],[\"chat.channel\",[30,2,[\"routeModels\"]],[28,[32,1],[\"chat.channel_info.back_to_channel\"],null]]],null],[1,\"\\n\"]],[]]],[1,\"        \"],[8,[30,1,[\"ChannelTitle\"]],null,[[\"@channel\"],[[30,2]]],null],[1,\"\\n      \"]],[1]]]]],[1,\"\\n\\n      \"],[8,[32,2],null,[[\"@channel\"],[[30,2]]],null],[1,\"\\n\\n      \"],[10,0],[14,0,\"c-channel-info\"],[12],[1,\"\\n        \"],[8,[32,3],null,[[\"@channel\"],[[30,2]]],null],[1,\"\\n        \"],[46,[28,[31,2],null,null],null,null,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"navbar\",\"@channel\"],false,[\"if\",\"component\",\"-outlet\"]]",
      "moduleName": "/home/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/routes/channel-info.js",
      "scope": () => [_navbar.default, _i18n.default, _chatChannelStatus.default, _channelInfoNav.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatRoutesChannelInfo;
});