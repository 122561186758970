define("discourse/plugins/chat/discourse/components/chat/routes/channel", ["exports", "@glimmer/component", "@ember/service", "discourse/plugins/chat/discourse/components/chat/navbar", "discourse/plugins/chat/discourse/components/chat-side-panel", "discourse/plugins/chat/discourse/components/full-page-chat", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _navbar, _chatSidePanel, _fullPageChat, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatRoutesChannel extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    get getChannelsRoute() {
      return this.args.channel.isDirectMessageChannel ? "chat.direct-messages" : "chat.channels";
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="c-routes --channel">
          <Navbar as |navbar|>
            {{#if this.site.mobileView}}
              <navbar.BackButton @route={{this.getChannelsRoute}} />
            {{/if}}
            <navbar.ChannelTitle @channel={{@channel}} />
            <navbar.Actions as |action|>
              <action.OpenDrawerButton />
              <action.ThreadsListButton @channel={{@channel}} />
            </navbar.Actions>
          </Navbar>
    
          <FullPageChat
            @channel={{@channel}}
            @targetMessageId={{@targetMessageId}}
          />
        </div>
    
        <ChatSidePanel>
          {{outlet}}
        </ChatSidePanel>
      
    */
    {
      "id": "2Mj98vOV",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"c-routes --channel\"],[12],[1,\"\\n      \"],[8,[32,0],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"          \"],[8,[30,1,[\"BackButton\"]],null,[[\"@route\"],[[30,0,[\"getChannelsRoute\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"        \"],[8,[30,1,[\"ChannelTitle\"]],null,[[\"@channel\"],[[30,2]]],null],[1,\"\\n        \"],[8,[30,1,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[30,3,[\"OpenDrawerButton\"]],null,null,null],[1,\"\\n          \"],[8,[30,3,[\"ThreadsListButton\"]],null,[[\"@channel\"],[[30,2]]],null],[1,\"\\n        \"]],[3]]]]],[1,\"\\n      \"]],[1]]]]],[1,\"\\n\\n      \"],[8,[32,1],null,[[\"@channel\",\"@targetMessageId\"],[[30,2],[30,4]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[32,2],null,null,[[\"default\"],[[[[1,\"\\n      \"],[46,[28,[31,2],null,null],null,null,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"navbar\",\"@channel\",\"action\",\"@targetMessageId\"],false,[\"if\",\"component\",\"-outlet\"]]",
      "moduleName": "/home/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/routes/channel.js",
      "scope": () => [_navbar.default, _fullPageChat.default, _chatSidePanel.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatRoutesChannel;
});